import {
    
    CONFIRMED_GET_UPCOMING_SESSION,
    CONFIRMED_GET_RECENT_SESSION,
    CONFIRMED_GET_SESSION_REPORT_SESSION,
    CONFIRMED_GET_MODAL_FILTER_RECORD_SESSION,
    CONFIRMED_GET_CLIENT_RECORD,
    CONFIRMED_GET_STUDENT_RECORD
} from '../actions/BangTypes';
import {
    RESET_PASSWORD_FAILED_ACTION
} from '../actions/ResetPasswordAction';

const initialState = {
    data:[],
};

const initUpcomingState = {
    data: [],
};
export function UpcomingReportReducer(state = initUpcomingState, actions) {
    if (actions.type === CONFIRMED_GET_UPCOMING_SESSION) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    return state;
}
const initSessionReportState = {
    data: [],
};
export function SessionReportReducer(state = initSessionReportState, actions) {
    if (actions.type === CONFIRMED_GET_SESSION_REPORT_SESSION) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    return state;
}

const initResetPasswordReducerState = {
    data: [],
};
export function ResetPasswordReducer(state = initResetPasswordReducerState, actions) {
    if (actions.type === RESET_PASSWORD_FAILED_ACTION) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    return state;
}

export function PostsReducer(state = initialState, actions) {
    
    
    if (actions.type === CONFIRMED_GET_RECENT_SESSION) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    
    if (actions.type === CONFIRMED_GET_MODAL_FILTER_RECORD_SESSION) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    if (actions.type === CONFIRMED_GET_CLIENT_RECORD) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    if (actions.type === CONFIRMED_GET_STUDENT_RECORD) {
        return {
            ...state,
            data: actions.payload,
        };
    }
    return state;
}
