
export const GET_UPCOMING_SESSION = '[Upcoming session Action] Get Upcoming session';
export const CONFIRMED_GET_UPCOMING_SESSION = '[Upcoming session Action] Confirmed Get Upcoming session';

export const GET_RECENT_SESSION = '[Recent session Action] Get Recent session';
export const CONFIRMED_GET_RECENT_SESSION = '[Recent session Action] Confirmed Get Recent session';

export const GET_SESSION_REPORT_SESSION = '[Session Report Action] Get Session Report';
export const CONFIRMED_GET_SESSION_REPORT_SESSION = '[Session Report Action] Confirmed Get Session Report';

export const GET_MODAL_FILTER_RECORD_SESSION = '[Modal Filter Report Action] Get Modal Filter Report';
export const CONFIRMED_GET_MODAL_FILTER_RECORD_SESSION = '[Modal Filter Report Action] Confirmed Get Modal Filter Report';

export const GET_CLIENT_RECORD = '[Get Client Record Action] Get Client Report';
export const CONFIRMED_GET_CLIENT_RECORD = '[Get Client Record Action] Get Client Report';

export const GET_STUDENT_RECORD = '[Get Student Record Action] Get Student Report';
export const CONFIRMED_GET_STUDENT_RECORD = '[Get Student Record Action] Get Student Report';