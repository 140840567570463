import axiosInstance from './AxiosInstance';
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function login(username, password) {
    const postData = {
        "user_name": username,
        password,
        "entity_type": 2,
        returnSecureToken: true,
    };
    return axiosInstance.post(`/clientAuth`, postData);
}

export function formatError(errorResponse) {

    switch (errorResponse) {
        case 'EMAIL_EXISTS':
            return 'Email already exists';

        case 'EMAIL_NOT_FOUND':
            return 'Email not found';
        case 'INVALID_PASSWORD':
            return 'Invalid Password';
        case 'USER_DISABLED':
            return 'User Disabled';
        case 'INVALID_CREDENTIALS':
            return "Invalid credentials or user doesn't exist by that username.";

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expires_in * 1000,
    );
    localStorage.setItem('tokenDetails', JSON.stringify(tokenDetails));
}
export function saveUserDetailInLocalStorage(userDetails) {

    localStorage.setItem('userDetails', JSON.stringify(userDetails.data));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
