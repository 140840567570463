import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
} from 'redux';
import {
    PostsReducer, 
    UpcomingReportReducer,
    SessionReportReducer,
    ResetPasswordReducer
} from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';



const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appreducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    upcomingData:UpcomingReportReducer,
    sessionReportData:SessionReportReducer,
    ResetPasswordData:ResetPasswordReducer,
});
const rootReducer = (state, action) => {   
    if (action.type === '[Logout action] logout action') {       
        return appreducers(undefined, action)
    }    
    return appreducers(state, action)
}

export const store = createStore(rootReducer, composeEnhancers(middleware));
