import {
    getResetPassword
} from '../../services/BangService';
import { toast } from 'react-toastify';

export const RESET_PASSWORD_FAILED_ACTION = '[Reset password failed action] Reset Password Failed';

toast.configure();

export function ResetPasswordAction(item) {

    return (dispatch) => {
        getResetPassword(item)
            .then((response) => {
                if (response.data.status === 'failure') {
                    const errorMessage = formatError('Incorrect_Password');
                    dispatch(ResetPasswordFailedAction(errorMessage));
                }
                else {
                    toast.success(response.data.Message);
                }
            })
            .catch((error) => {
                const errorMessage = formatError(error);
                dispatch(ResetPasswordFailedAction(errorMessage));
            });

    };
}

export function ResetPasswordFailedAction(data) {
    return {
        type: RESET_PASSWORD_FAILED_ACTION,
        payload: data,
    };
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'Incorrect_Password':
            return 'Incorrect Password';
        default:
            return '';
    }
}