import axios from 'axios';
import axiosInstance from '../../services/AxiosInstance';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    saveUserDetailInLocalStorage
} from '../../services/AuthService';

export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';


export function logout(history) {
    localStorage.removeItem('tokenDetails');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('clientDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, history) {

    return (dispatch) => {
        const tokenDetailsString = localStorage.getItem('userDetails');

        if (tokenDetailsString == null) {
            
            const postTokenData = {
                "client_secret": process.env.REACT_APP_FP_CLIENT_SECRET_KEY,
                "client_id": process.env.REACT_APP_FP_CLIENT_ID,
                "grant_type": process.env.REACT_APP_FP_CLIENT_GRAND_TYPE,  // client_credentials, password, password_credentials
            };
                
            axios.post(
                process.env.REACT_APP_FP_AUTH_TOKEN_URL,
                postTokenData,
                {
                    headers: {
                        Accept: 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    }
                })
                .then(function (response_auth_token) {
                    saveTokenInLocalStorage(response_auth_token.data);
                    runLogoutTimer(
                        dispatch,
                        response_auth_token.data.expires_in * 1000,
                        history,
                    );
                    login(username, password)
                        .then((response) => {
                            
                            let responseHandle = JSON.parse(JSON.stringify(response.data));
                            if (responseHandle.status === 'failure') {
                                const errorMessage = formatError('INVALID_CREDENTIALS');
                                dispatch(loginFailedAction(errorMessage));
                            }
                            else {
                                saveUserDetailInLocalStorage(response);
                                const ClientPostData = {
                                    "contact_id": responseHandle.contact_id,
                                    returnSecureToken: true,
                                };
                                axiosInstance.post('/clientData', ClientPostData).then(response => {
        
                                    const contact_detail = {
                                        "status":"success",
                                        'client_number':response.data.phone,
                                        'client_email':response.data.email
                                    }
                                    localStorage.setItem('clientDetails', JSON.stringify(contact_detail));
                                });
                                dispatch(loginConfirmedAction(response));
                                history.push('/home');
                            }

                        })
                        .catch((error) => {
                            const errorMessage = formatError(error.response);
                            dispatch(loginFailedAction(errorMessage));
                        });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            login(username, password)
                .then((response) => {
                    saveTokenInLocalStorage(response);
                    runLogoutTimer(
                        dispatch,
                        response.expires_in * 1000,
                        history,
                    );
                    dispatch(loginConfirmedAction(response));
                    history.push('/home');
                })
                .catch((error) => {
                    const errorMessage = formatError(error.response.data);
                    dispatch(loginFailedAction(errorMessage));
                });
        }

    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
