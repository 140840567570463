import axiosInstance from './AxiosInstance';
import Moment from 'moment';

export function getUpcomingSession() {
    const UserDetailsString = JSON.parse(localStorage.getItem('userDetails'));
    let postData = {
        "contact_id": UserDetailsString ? UserDetailsString.contact_id : '',
        "timezone": UserDetailsString ? UserDetailsString.timezone : '',
        "limit": 5,
        returnSecureToken: true,
    };
    return axiosInstance.post('/clientUpcomingSessions', postData);
}

export function getRecentSession(getAllFiltered) {
    const UserDetailsString = JSON.parse(localStorage.getItem('userDetails'));
    let postData = {
        "contact_id": UserDetailsString ? UserDetailsString.contact_id : '',
        "timezone": UserDetailsString ? UserDetailsString.timezone : '',
        // "limit":5,
        returnSecureToken: true,
    };

    if (getAllFiltered.hasOwnProperty('getFilteredData') && getAllFiltered['getFilteredData'].length) {
        getAllFiltered['getFilteredData'].map(filterItem => {

            if (filterItem.FilterType === "Students" && filterItem.FilterValues.length > 0) {
                var StudentArr = [];
                filterItem.FilterValues.map(fVal => {
                    StudentArr.push(fVal.id);
                });
                postData = { ...postData, "student_id": JSON.stringify(StudentArr) };
            }

            if (filterItem.FilterType === "Subjects" && filterItem.FilterValues.length > 0) {
                var SubjectArr = [];
                filterItem.FilterValues.map(fVal => {
                    SubjectArr.push(fVal.id);
                });
                postData = { ...postData, "subject_id": JSON.stringify(SubjectArr) };
            }
            if (filterItem.FilterType === "Tutors" && filterItem.FilterValues.length > 0) {
                var TutorArr = [];
                filterItem.FilterValues.map(fVal => {
                    TutorArr.push(fVal.id);
                });
                postData = { ...postData, "tutor_id": JSON.stringify(TutorArr) };
            }
        });
    };

    if (getAllFiltered.hasOwnProperty('filterDateRange')) {
        postData = { ...postData, "app_start_date": getAllFiltered['filterDateRange'].app_start_date };
        postData = { ...postData, "app_end_date": getAllFiltered['filterDateRange'].app_end_date };
    };


    return axiosInstance.post('/clientSessions', postData);
}

export function getSessionReport(getAllFiltered) {
    const UserSessionReport = JSON.parse(localStorage.getItem('userDetails'));
    let postData = {
        "contact_id": UserSessionReport ? UserSessionReport.contact_id : '',
        "timezone": UserSessionReport ? UserSessionReport.timezone : '',
        returnSecureToken: true
    };

    if (getAllFiltered.hasOwnProperty('getFilteredData') && getAllFiltered['getFilteredData'].length > 0) {
        getAllFiltered['getFilteredData'].map(filterItem => {

            if (filterItem.FilterType === "Students" && filterItem.FilterValues.length > 0) {
                var StudentArr = [];
                filterItem.FilterValues.map(fVal => {
                    StudentArr.push(fVal.id);
                });
                postData = { ...postData, "student_id": JSON.stringify(StudentArr) };
            }
            if (filterItem.FilterType === "Subjects" && filterItem.FilterValues.length > 0) {
                var SubjectArr = [];
                filterItem.FilterValues.map(fVal => {
                    SubjectArr.push(fVal.id);
                });
                postData = { ...postData, "subject_id": JSON.stringify(SubjectArr) };
            }
            if (filterItem.FilterType === "Tutors" && filterItem.FilterValues.length > 0) {
                var TutorArr = [];
                filterItem.FilterValues.map(fVal => {
                    TutorArr.push(fVal.id);
                });
                postData = { ...postData, "tutor_id": JSON.stringify(TutorArr) };
            }
        });
    };

    if (getAllFiltered.hasOwnProperty('filterDateRange')) {
        postData = { ...postData, "app_start_date": getAllFiltered['filterDateRange'].app_start_date };
        postData = { ...postData, "app_end_date": getAllFiltered['filterDateRange'].app_end_date };
    };


    return axiosInstance.post('/clientSessions', postData);
}

export function getModalFilterRecord() {
    const UserModaFilterRecord = JSON.parse(localStorage.getItem('userDetails'));
    const postData = {
        "contact_id": UserModaFilterRecord ? UserModaFilterRecord.contact_id : '',
        "timezone": UserModaFilterRecord ? UserModaFilterRecord.timezone : '',
        returnSecureToken: true,
    };

    return axiosInstance.post('/clientFilterDropdown', postData);
}

export function getTotalHours() {
    const UserDetailsString = JSON.parse(localStorage.getItem('userDetails'));
    const postData = {
        "contact_id": UserDetailsString ? UserDetailsString.contact_id : '',
        "timezone": UserDetailsString ? UserDetailsString.timezone : '',
        "limit": 5,
        returnSecureToken: true,
    };

    return axiosInstance.post('/clientHoursData', postData);
}

export function getClientRecords() {
    const ClientRecordData = JSON.parse(localStorage.getItem('userDetails'));
    const postData = {
        "contact_id": ClientRecordData ? ClientRecordData.contact_id : '',
        returnSecureToken: true,
    };

    return axiosInstance.post('/clientData', postData); 
}

export function getStudentRecords() {
    const StudentRecordData = JSON.parse(localStorage.getItem('userDetails'));
    const postData = {
        "contact_id": StudentRecordData ? StudentRecordData.contact_id : '',
        returnSecureToken: true,
    };

    return axiosInstance.post('/studentData', postData);
}

export function getStudentUpdateRecords(item) {
    const StudentUpdateRecordData = JSON.parse(localStorage.getItem('userDetails'));
    const postData = {
        "student_id": item.studentId,
        "first_name": item.first_name,
        "last_name": item.last_name,
        "email": item.email,
        "gender": item.gender,
        "dob": Moment(item.date_of_birth).format('D-MM-yyyy'),
        "school": item.school,
        "grade": item.grade,
        returnSecureToken: true,
    };

    return axiosInstance.post('/studentUpdateData', postData);
}

export function formatMessage(messageResponse) {

    switch (messageResponse) {
        case 'SUCCESSFULLY UPDATED':
            return 'Student Data Updated Successfully';

        default:
            return '';
    }
}

export function getResetPassword(item) {
    const ResetPasswordData = JSON.parse(localStorage.getItem('userDetails'));

    const postData = {
        "user_name": ResetPasswordData ? ResetPasswordData.user_name : '',
        "entity_type": "2",
        "current_password": item.current_password,
        "new_password": item.new_password,
        returnSecureToken: true,
    };

    return axiosInstance.post('/resetPassword', postData);
}

export function formatData(rowData) {

    let itemData = [];
    for (let key in rowData) {
        itemData.push({ ...rowData[key], id: key });
    }

    return itemData;
}
