import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import Header from './components/Header/Header';
import SidePanel from './components/SidePanel';
import 'font-awesome/css/font-awesome.min.css';

const Login = lazy(() => import('./pages/Login/Login'));
const Home = lazy(() => import('./pages/Home/Home'));
const SessionReport = lazy(() => import('./pages/SessionReport/SessionReport'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));

function App(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, []);

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
        </Switch>
    );

    // Checked Auth. //...
    if (props.isAuthenticated) {
        routes = (
            <Switch>
                <Route path='/home' component={Home} exact />
                <Route path='/session-report' component={SessionReport} exact />
                <Route path='/profile' component={Profile} exact />
                <Route path='/reset-password' component={ResetPassword} exact />
                <Redirect from="/login" to="/home" exact />  {/**here check route login to home **/}
                <Redirect from="/" to="/home" exact />  {/**here check route / to home **/}
            </Switch>
        );
        return (
            <>
                <SidePanel />
                <div className="main-content">
                    <Header />
                    <Suspense fallback={<div>Loading...</div>}>
                        {routes}
                    </Suspense>
                </div>
            </>
        );
    }
    else {

        return (
            <Suspense fallback={<div>Loading...</div>}>
                {props.isAuthenticated === false ? <Login /> : routes}
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    const tokenDetailsString = JSON.parse(localStorage.getItem('tokenDetails'));

    let token_access_token = '';

    if (tokenDetailsString != null) {
        token_access_token = tokenDetailsString.access_token;
    }
    else {
       return  <Login/>;
    }

    return {
        isAuthenticated: isAuthenticated(token_access_token),
    };
};

export default withRouter(connect(mapStateToProps)(App));
