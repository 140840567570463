import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../store/actions/AuthActions';
import { isAuthenticated } from '../store/selectors/AuthSelectors';
import Dropdown from 'react-bootstrap/Dropdown';

function SidePanel(props) {
  const dispatch = useDispatch();
  function onLogout() {
    dispatch(logout(props.history));
  }

  function showRight() {
    
    if(document.body.classList.contains("show-menu") === '' || document.body.classList.contains("show-menu") === false){
      document.body.className = 'show-menu';
      document.getElementById("sidebarCollapse").className = 'navbar-toggler menu-toggle';
      document.getElementById("sidebarCollapseResponsive").className = 'collapse navbar-collapse show';
    } else {
      document.body.className = '';
      document.getElementById("sidebarCollapse").className = 'navbar-toggler menu-toggle collapsed';
      document.getElementById("sidebarCollapseResponsive").className = 'collapse navbar-collapse ';
    };
  }

  let UserDetailsString = JSON.parse(localStorage.getItem('userDetails'));

  let AuthUserRecord = { first_name: '', last_name: '' };

  if (UserDetailsString) {

    AuthUserRecord = {
      "first_name": UserDetailsString.first_name,
      "last_name": UserDetailsString.last_name,
    };
  }

  return (
    <>

      <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
        <div className="container-fluid">
          {/* Toggler */}
          <button id="sidebarCollapse" className="navbar-toggler menu-toggle collapsed" onClick={showRight} type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="true" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <a className="navbar-brand" href="/home"> 
            <img src="./logo-white@2x.png" className="navbar-brand-img 
          mx-auto" alt="..." />
            <img src="./logo.png" className="navbar-brand-img 
          mx-auto black-logo" alt="..." />
          </a>
          {/* <a href="#" id="notification mobile" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fb fb-bell" />
            <span className="notification-badge notification-badge-number bg-primary">10</span>
            {/* Menu */}
          {/* </a>  */}
          <Dropdown className="d-md-none d-lg-none">
            <Dropdown.Toggle variant="" id="notification mobile" className="dropdown-toggle" role="button">
              <i className="fb fb-bell" />
              <span className="notification-badge notification-badge-number bg-primary">1</span>
            </Dropdown.Toggle>


            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item href="javascript:void(0);" className="dropdown-item d-flex align-items-center p-3">
                <img className="avatar avatar-sm p-1 me-2" src="../images/Profile_Pic.png" alt="Jason Doe" />
                <div className="pt-1">
                  <h6 className="fw-bold mb-0">Comming Soon</h6>
                  <span className="text-muted text-sm">Comming Soon</span>
                </div>
              </Dropdown.Item>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-center" href="#">
                <small class="fw-bold text-uppercase"> View all messages </small>
              </a>

            </Dropdown.Menu>
          </Dropdown>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="notification"><a href="#" id="notification mobile" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </a>
            <a className="dropdown-item d-flex align-items-center p-3" href="#"> <img className="avatar avatar-sm p-1 me-2" src="../images/Profile_Pic.png" alt="Jason Doe" />
              <div className="pt-1">
                <h6 className="fw-bold mb-0">Tejas patel</h6><span className="text-muted text-sm">Project Manager at svaapta
                  it</span>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center p-3" href="#"> <img className="avatar avatar-sm p-1 me-2" src="../images/Profile_Pic.png" alt="Jason Doe" />
              <div className="pt-1">
                <h6 className="fw-bold mb-0">sanni patel</h6><span className="text-muted text-sm">Web Designer at svaapta
                  it</span>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center p-3" href="#"> <img className="avatar avatar-sm p-1 me-2" src="../images/Profile_Pic.png" alt="Jason Doe" />
              <div className="pt-1">
                <h6 className="fw-bold mb-0">kishan patel</h6><span className="text-muted text-sm">ui/ux designer at svaapta
                  it</span>
              </div>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item text-center" href="#"> <small className="fw-bold text-uppercase">View all
              messages</small></a>
          </div>
          {/* Collapse */}
          <div className="navbar-user sm-block">
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic" className="profile-dropdown">
                <div className="avatar avatar-sm avatar-online">
                  <span>
                    {AuthUserRecord.first_name.charAt(0)}{AuthUserRecord.last_name.charAt(0)}
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="newdropdown">
                <Dropdown.Item >
                  <p>Welcome,<br />
                  {AuthUserRecord.first_name} {AuthUserRecord.last_name} !</p>
                </Dropdown.Item>
                <div class="dropdown-divider"></div>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/reset-password">Settings</Dropdown.Item>
                <Dropdown.Item href="javascript:void(0);" onClick={onLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="collapse navbar-collapse" id="sidebarCollapseResponsive">
            {/* Navigation */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/home">
                  <i className="fb fb-home"></i><span>Home</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/session-report">
                  <i className="fb fb-Session-Report" /><span>Session Reports</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/profile">
                  <i className="fb fb-My-profile" /><span>My Profile</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(SidePanel));