import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_FP_API_BASE_URL,
});


axiosInstance.interceptors.request.use((request) => {
    const tokenDetailsString = JSON.parse(localStorage.getItem('tokenDetails'));
    const token_auth = tokenDetailsString ? tokenDetailsString.token_type+" "+tokenDetailsString.access_token : '';
    request.headers.Authorization=token_auth;
    return request;
});

export default axiosInstance;
