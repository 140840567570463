export const isAuthenticated = (state) => {
    const userDetailsString = JSON.parse(localStorage.getItem('userDetails'));
    if(userDetailsString == null){
        return false;
    }

    if (state){
        return true
    }
    return false;
};
